:root {
  --space-0\.5: 0.125rem;
  --space-1: 0.25rem;
  --space-1\.5: 0.375rem;
  --space-2: 0.5rem;
  --space-2\.5: 0.625rem;
  --space-3: 0.75rem;
  --space-3\.5: 0.875rem;
  --space-4: 1rem;
  --space-5: 1.25rem;
  --space-6: 1.5rem;
  --space-7: 1.75rem;
  --space-8: 2rem;
  --space-9: 2.25rem;
  --space-10: 2.5rem;
  --space-11: 2.75rem;
  --space-12: 3rem;
  --space-14: 3.5rem;
  --space-16: 4rem;
  --space-18: 4.5rem;
  --space-20: 5rem;
  --space-22: 5.5rem;
  --space-24: 6rem;
  --space-28: 7rem;
  --space-32: 8rem;
  --space-36: 9rem;
  --space-40: 10rem;
}
