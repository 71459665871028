@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'swiper/scss';
@import 'swiper/scss/effect-fade';
@import './space.tokens.scss';
@import 'react-indiana-drag-scroll/dist/style.css';

:root {
  --gutter: var(--space-4);
  --container-width: 2000px;
  --neg-gutter: calc(-1 * var(--gutter));
  --navigation-announcement-height: var(--space-9);
  --navigation-height: var(--space-16);
  --border-radius-default: 4px;
  --error-foreground: #ff6666;
  --transition: 0.25s;
}

body {
  @apply bg-background-primary text-text-primary font-sans antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

@media all and (min-width: 768px) {
  :root {
    --gutter: var(--space-8);
    --navigation-height: var(--space-20);
  }
}

// Temp added as needed for the video player to take up the full size of container, find better fixc
video {
  object-fit: cover;
}

* {
  &:focus-visible {
    @apply ring-ring ring-2 ring-inset;

    outline: none;
  }
}

@layer base {
  *,
  ::before,
  ::after {
    @apply border-border-primary;
  }
}
